import { Disclosure } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/solid';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { PageH1 } from '../components/headers';
import Layout from '../components/layout';
import SEO from '../components/seo';

const LicenseDisplay = ({ licenseText }) => (
  <Disclosure>
    {({ open }) => (
      <>
        <Disclosure.Button className="p-1 flex items-center border border-gray-700 rounded w-full hover:bg-lighter-bg focus:ring focus:ring-indigo-400">
          <ChevronRightIcon
            className={`${open ? 'transform rotate-90' : ''} h-5 w-5`}
          />
          Open License
        </Disclosure.Button>
        <Disclosure.Panel className="text-gray-500">
          <pre>{licenseText}</pre>
        </Disclosure.Panel>
      </>
    )}
  </Disclosure>
);

LicenseDisplay.propTypes = {
  licenseText: PropTypes.string.isRequired,
};

const noIndexMeta = [{ property: 'robots', content: 'noindex' }];

export const query = graphql`
  {
    allPackageLicense {
      edges {
        node {
          identifier
          license
          licenseText
          package
          url
          version
        }
      }
    }
  }
`;

const OSLicensesPage = ({ data }) => (
  <Layout>
    <SEO title="Open Source Licenses" meta={noIndexMeta} />
    <PageH1 classes="mt-5 mb-8 mx-8">Open Source Licenses</PageH1>
    <div
      className="mx-4 mb-12 lg:mx-16 prose md:prose-lg"
      style={{ maxWidth: 'none' }}
    >
      <p>
        This Website relies heavily on awesome Open Source Software. Thanks to
        all the awesome developers behind it!
      </p>
      <div>
        Notable mentions are:
        <ul>
          <li>
            <a className="standard-link" href="https://www.gatsbyjs.com/">
              Gatsby
            </a>
            <span> - and it&apos;s whole awesome ecosystem</span>
          </li>
          <li>
            <a className="standard-link" href="https://reactjs.org/">
              React
            </a>
            <span> (Which Gatsby depends on)</span>
          </li>
          <li>
            <a className="standard-link" href="https://tailwindcss.com/">
              TailwindCSS
            </a>
            <span> (CSS Library)</span>
          </li>
        </ul>
      </div>
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="p-2 flex items-center border border-gray-700 rounded w-full hover:bg-lighter-bg focus:ring focus:ring-indigo-400">
              <ChevronRightIcon
                className={`${open ? 'transform rotate-90' : ''} h-5 w-5`}
              />
              All Licenses
            </Disclosure.Button>
            <Disclosure.Panel className="text-gray-400 mx-6">
              {data.allPackageLicense.edges.map(({ node }) => (
                <div
                  key={node.identifier}
                  className="border-b border-gray-600 mt-4 pb-4"
                >
                  {node.url ? (
                    <a className="standard-link" href={node.url}>
                      {node.identifier}
                    </a>
                  ) : (
                    <span className="text-gray-100">{node.identifier}</span>
                  )}
                  <div>License: {node.license || '?'}</div>
                  {node.licenseText && (
                    <LicenseDisplay licenseText={node.licenseText} />
                  )}
                </div>
              ))}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  </Layout>
);

OSLicensesPage.propTypes = {
  data: PropTypes.shape({
    allPackageLicense: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            identifier: PropTypes.string.isRequired,
            license: PropTypes.string,
            licenseText: PropTypes.string,
            package: PropTypes.string.isRequired,
            url: PropTypes.string,
            version: PropTypes.string.isRequired,
          }).isRequired,
        }).isRequired
      ).isRequired,
    }).isRequired,
  }).isRequired,
};

export default OSLicensesPage;
